import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const Privacy = () => (
  <Layout>
    <SEO title="Privacy" />
    <div style={{ padding: 100 }}>
      <h1>Privacy</h1>
      <h3>Introduction</h3>
      <p>
        Quantifai, Inc. ("Company") is committed to protecting the privacy of
        visitors of the Quantifai website(s), individuals who register to use
        the products and services, individuals who register to attend the
        Company's corporate or other events, and business partners. This Privacy
        Notice ("Privacy Notice" or "Notice") describes our privacy practices in
        relation to the use of Quantifai websites (including any customer portal
        or interactive customer website), its software, services, solutions,
        tools, and related applications, services, and programs, including
        attendance of corporate events and research and marketing activities,
        offered by Quantifai (the "Services") as well as your choices regarding
        use, access and correction of personal data.
      </p>
      <p>
        This Privacy Notice is meant to help you understand, among other things,
        what personal data we collect, why we collect it, and what we do with
        it. In addition to this notice, we further elaborate on two major
        topics:
      </p>
      <p>
        <ul>
          <li>
            GDPR Statement: The EU General Data Protection Regulation (GDPR),
            enforceable as of May 25, 2018, imposes additional requirements upon
            companies to strengthen the security around and enhance the
            protection of personal data of EU residents. The Company has a
            dedicated, cross-functional team overseeing Quantifai's GDPR
            readiness. We discuss Quantifai's efforts and commitment to GDPR in
            the Quantifai GDPR Resource Center.
          </li>
          <li>
            Cookie Policy: This Cookie Policy addresses how we use cookies when
            you visit Quantifai. We want to be clear about how we collect and
            use data related to you via cookies.
          </li>
        </ul>
      </p>
      <p>
        Quantifai, Inc. has complied with the EU-US and Swiss-US Privacy Shield
        Framework; as part of this, we have certified to the US Department of
        Commerce that we adhere to the Privacy Shield principles of: Notice;
        Choice; Accountability for Onward Transfer; Security; Data Integrity and
        Purpose Limitation; Access; and Recourse, Enforcement, and Liability
        (the "Principles"), with respect to personal data that we process on
        behalf of our Customers established in the European Union and
        Switzerland. For more information about our Privacy Shield commitment,
        refer to the Privacy Shield section below.
      </p>
      <p>
        You can learn more about Privacy Shield at{" "}
        <a href="https://www.privacyshield.gov">
          https://www.privacyshield.gov
        </a>{" "}
        and see our Privacy Shield self-certification at{" "}
        <a href="https://www.privacyshield.gov/list">
          https://www.privacyshield.gov/list
        </a>
        . Quantifai, Inc.'s self-certification to the Privacy Shield is subject
        to the investigatory and enforcement authority of the Federal Trade
        Commission.
      </p>
      <h3>What information do we collect?</h3>
      <p>
        We collect information from you when you register on our site or fill
        out a form. Any data we request that is not required will be specified
        as voluntary or optional. When registering on our site, as appropriate,
        you may be asked to enter your: name, e-mail address, mailing address or
        credit card information. You may, however, visit our site anonymously.
      </p>
      <p>The information we may collect includes, without limitation:</p>
      <p>
        <ul>
          <li>
            Contact data, such as: IP address, Email addresses, Phone numbers,
            Text notes about customers entered by end users, Related customers
            and organizations, Business addresses, Titles/Positions, and other
            similar contact data.
          </li>
          <li>
            Metadata and data for interactions, such as: Email addresses and
            names of participants, Email headers that contain Personal Data,
            Metadata of emails (times sent, etc.) that are linked to Personal
            Data, Metadata about calls and meetings (timing, participants, etc.)
            that are linked to Personal Data, Structured data stored by Company
            about Data Subjects, including text notes about Data Subjects that
            contain Personal Data, and structured data about Data Subjects (e.g.
            columns and "magic columns") that contain Personal Data.
          </li>
        </ul>
      </p>
      <h3>What do we use your information for?</h3>
      <p>
        Any of the information we collect from you may be used in one of the
        following ways:
      </p>
      <p>
        <ul>
          <li>
            We provide customer relationship management services to you. We
            process personal data as necessary to perform these services. In
            addition to storing and retrieving data, we carry out necessary
            processing to generate reports and insights of your interactions
            with your customers on your behalf
          </li>
          <li>
            To personalize your experience (your information helps us to better
            respond to your individual needs)
          </li>
          <li>
            To improve our website (we continually strive to improve our service
            based on the information and feedback we receive from you)
          </li>
          <li>
            To improve customer service (your information helps us to more
            effectively respond to your customer service and support needs)
          </li>
        </ul>
      </p>
      <h3>Do we use cookies?</h3>
      <p>
        Yes. Cookies are small files that a site or its service provider
        transfers to your computer's hard drive through your Web browser (if you
        allow it) that enables the sites or service provider's systems to
        recognize your browser and capture and remember certain information. If
        you turn off cookies, you may still access the quantif.ai site and learn
        about the product, but using the Quantifai app requires cookies in order
        to access workflow administration.
      </p>
      <h3>Processing of data in EU</h3>
      <p>
        For individuals in the European Union, our processing (i.e. use) of your
        personal data is justified on the following legal bases:
      </p>
      <p>
        <ul>
          <li>
            Performance of Contract: the processing is necessary to perform a
            contract with you or take steps to enter into a contract at your
            request;
          </li>
          <li>
            Compliance with Law: the processing is necessary for us to comply
            with a relevant legal obligation (for example, laws which require us
            to collect tax information from customers, carry out checks on
            customers, or which compel us to disclose information to public
            authorities or regulators);
          </li>
          <li>
            Our Legitimate Interests: the processing is in our legitimate
            interests, subject to your interests and fundamental rights, and
            notably our legitimate interest in using applicable data to conduct
            and develop our business activities, such as developing and
            maintaining relationships with our customers (the majority of the
            processing covered by this notice is legitimate interest based);
          </li>
          <li>
            Defend Our Rights: where the processing is necessary to the
            establishment, exercise or defence of legal claims; or
          </li>
          <li>
            With Your Consent: you have consented to the processing (for
            example, where we are required by local law to rely upon your prior
            consent for the purposes of direct marketing).
          </li>
        </ul>
      </p>
      <p>
        In all cases where legitimate interests is relied upon as a lawful
        basis, we take steps to ensure that our legitimate interests are not
        outweighed by any prejudice to your rights and freedoms. This is
        achieved in a number of ways, including through the application of
        principles of data minimization and security, and by taking steps to
        ensure that personal data is only collected or otherwise obtained where
        it is relevant to the lawful business activities, and where using
        personal data is reasonably necessary for those activities.
      </p>
      <h3>Do we disclose any information to outside parties?</h3>
      <p>
        We do not sell, trade, or otherwise transfer your personal information
        except in accordance with this policy. This does not include trusted
        third parties who assist us in operating our website, conducting our
        business, or servicing you, so long as those parties agree to keep this
        information confidential. We may also release your information when we
        believe release is appropriate to comply with the law, enforce our site
        policies, or protect ours or others' rights, property, or safety.
        However, non-personally identifiable visitor information may be provided
        to other parties for marketing, advertising, or other uses.
      </p>
      <p>
        We use sub-processors to provide us with some services that are
        necessary to provide the features of our website. Those subprocessors
        and the services they provide are listed below:
      </p>
      <p>
        <ul>
          <li>Google: Gmail API</li>
          <li>Amazon: Amazon Web Services</li>
          <li>Segment: Analytics</li>
        </ul>
      </p>
      <p>
        If personal data is transferred outside the EU to other Quantifai, Inc.
        group companies or to third party service providers, we will take steps
        to ensure that your personal data receives the same level of protection
        as if it remained within the EU, including by entering into data
        transfer agreements, using the European Commission approved Standard
        Contractual Clauses, or by relying on certification schemes such as the
        EU - US Privacy Shield. For transfers to other Quantifai, Inc. group
        companies in the United States (a country that has not received a
        decision from the European Commission determining that the United States
        provides adequate protection to personal data), we have put in place
        European Commission approved Standard Contractual Clauses, which protect
        personal data transferred between Quantifai, Inc. entities. You have a
        right to obtain details of the mechanism under which your personal data
        is transferred outside of the EU by contacting{" "}
        <a href="mailto:kevin@quantif.ai">kevin@quantif.ai</a>
      </p>
      <h3>Access and Data Subject Rights</h3>
      <p>
        Individuals have the right to access the personal data processed about
        them, subject to applicable law; individuals may request to access their
        personal data processed by us by emailing us at{" "}
        <a href="mailto:kevin@quantif.ai">kevin@quantif.ai</a>.
      </p>
      <p>
        Subject to applicable law, you may also have some or all of the
        following rights available to you in respect of your personal data:
      </p>
      <p>
        <ul>
          <li>
            to obtain a copy of your personal data together with information
            about how and on what basis that personal data is processed;
          </li>
          <li>
            to rectify inaccurate personal data (including the right to have
            incomplete personal data completed);
          </li>
          <li>
            to erase your personal data (in limited circumstances, such as where
            it is no longer necessary in relation to the purposes for which it
            was collected or processed);
          </li>
          <li>
            to restrict processing of your personal data under certain
            circumstances;
          </li>
          <li>
            to port your data in machine-readable format to a third party (or to
            you) when we justify our processing on the basis of your consent or
            the performance of a contract with you;
          </li>
          <li>
            to withdraw your consent to our processing of your personal data
            (where that processing is based on your consent);
          </li>
          <li>
            and to obtain, or see a copy of the appropriate safeguards under
            which your personal data is transferred to a third country or
            international organization.
          </li>
        </ul>
      </p>
      <p>
        In addition to the above rights, under EU data protection law,
        applicable individuals have the right to object, on grounds relating to
        your particular situation, at any time to any processing of your
        personal data for which we have justified on the basis of a legitimate
        interest, including profiling (as opposed to your consent) or to perform
        a contract with you. You also have the right to object at any time to
        any processing of your personal data for direct marketing purposes,
        including profiling for marketing purposes.
      </p>
      <p>
        You also have the right to lodge a complaint with your local supervisory
        authority for data protection.
      </p>
      <p>
        In relation to all of these rights, please contact us at{" "}
        <a href="mailto:kevin@quantif.ai">kevin@quantif.ai</a>. Please note that
        we may request proof of identity, and we reserve the right to charge a
        fee where permitted by law, especially if your request is manifestly
        unfounded or excessive. We will endeavor to respond to your request
        within all applicable timeframes.
      </p>
      <p>
        If you contact us regarding Customer Data for which we are a data
        processor, we will attempt to refer your request to the relevant
        Customer, and data controller for your personal data.
      </p>
      <h3>Data Retention and Data Subject Rights</h3>
      <p>
        If you are an EU citizen and you wish to request that we a) identify the
        personal information we have about you; b) delete the personal
        information we have about you; or c) exercise any other data subject
        right you may have under applicable law, please email us at{" "}
        <a href="mailto:kevin@quantif.ai">kevin@quantif.ai</a>.
      </p>
      <h3>California Online Privacy Protection Act Compliance</h3>
      <p>
        Because we value your privacy we have taken the necessary precautions to
        be in compliance with the California Online Privacy Protection Act. We
        therefore will not distribute your personal information to outside
        parties without your consent.
      </p>
      <h3>Online Privacy Policy Only</h3>
      <p>
        This online privacy policy applies only to information collected through
        our website and not to information collected offline.
      </p>
      <h3>Privacy Shield Dispute Resolution</h3>
      <p>
        We encourage EU and Swiss individuals who have questions or complaints
        about how we process their personal data under Privacy Shield to contact
        us at <a href="mailto:kevin@quantif.ai">kevin@quantif.ai</a>. We will
        work to resolve your issue as quickly as possible, but in any event,
        within 45 days of receipt.
      </p>
      <p>
        If you have an unresolved privacy or data use concern that we have not
        addressed satisfactorily, please contact our U.S.-based third party
        dispute resolution provider (free of charge) at
        <a href="https://www.jamsadr.com/eu-us-privacy-shield">
          https://www.jamsadr.com/eu-us-privacy-shield
        </a>
        .
      </p>
      <p>
        If you are an EU or Swiss individual and unable to resolve any
        complaints through any of the above methods, you may be able to invoke
        binding arbitration through a Privacy Shield panel, in accordance with
        the Privacy Shield Framework.
      </p>
      <h3>EU Residents</h3>
      <p>
        Quantifai processes personal data only on documented instructions from
        the controller, including with regard to transfers of personal data to a
        third country or an international organization, unless required to do so
        by Union or Member State law to which the processor is subject; in such
        a case, the processor shall inform the controller of that legal
        requirement before processing, unless that law prohibits such
        information on important grounds of public interest;
      </p>
      <p>
        Quantifai ensures that persons authorized to process the personal data
        have committed themselves to confidentiality or are under an appropriate
        statutory obligation of confidentiality;
      </p>
      <p>
        Quantifai takes Technical and Organizational measures in accordance with
        Article 32 of the GDPR;
      </p>
      <p>
        Quantifai respects the conditions referred to in paragraphs 2 and 4 of
        Article 28.3 of the GDPR for engaging another processor;
      </p>
      <p>
        Quantifai will use commercially reasonable efforts, insofar as this is
        possible, for the fulfilment of the controller's obligation to respond
        to requests for exercising the data subject's rights laid down in
        Chapter III of the GPDR;
      </p>
      <p>
        Quantifai assists the controller in ensuring compliance with the
        obligations pursuant to Articles 32 to 36 of the GDPR taking into
        account the nature of processing and the information available to the
        processor;
      </p>
      <p>
        Quantifai will, at the choice of the controller, which should be
        exercised via the Service or by emailing the choice to{" "}
        <a href="mailto:kevin@quantif.ai">kevin@quantif.ai</a>, delete or return
        all the personal data to the controller after the end of the provision
        of services relating to processing, and will delete existing copies
        unless Union or Member State law requires storage of the personal data;
      </p>
      <p>
        Quantifai will make available to the controller all information
        necessary to demonstrate compliance with the obligations laid down in
        Article 28.3 of the GDPR and allow for and contribute to audits,
        including inspections, conducted by the controller or another auditor
        mandated by the controller.
      </p>
      <h3>Other Disclosures</h3>
      <p>
        Law Enforcement or National Security. In accordance with our legal
        obligations, we may also transfer Customer Data, subject to a lawful
        request, to public authorities for law enforcement or national security
        purposes.
      </p>
      <p>
        Additional Disclosures. We may also disclose Customer Data (including
        any personal data), where otherwise required by law.
      </p>
      <p>
        Business Transfers. We may share personal data with third parties in
        connection with potential or actual sale of our company or any of our
        assets, or those of any affiliated company, in which case personal data
        held by us about our users may be one of the transferred assets.
        Quantifai, Inc. recognizes potential liability in cases of onward
        transfer to third parties. Quantifai, Inc. will not transfer any
        personal information to a third-party without first ensuring that the
        third-party adheres to the Privacy Shield principles.
      </p>
      <p>
        Just-in-Time Disclosures. Additional disclosures or information about
        processing of personal data related to specific websites, mobile
        applications, products, services, or programs may be provided to you.
        These may supplement and/or clarify Quantifai, Inc.'s' privacy practices
        in specific circumstances and provide you with additional choices as to
        how Quantifai, Inc. may process your personal data.
      </p>
      <h3>Your Consent</h3>
      <p>
        By using our site, you consent to our privacy policy. If you do not
        consent to the collection and processing of the information required to
        be processed, we are unable to provide you with our service, and you
        should not use our site.
      </p>
      <h3>Changes to our Privacy Policy</h3>
      <p>
        If we decide to change our privacy policy, we will post those changes on
        this page, send an email notifying you of any changes, and/or update the
        Privacy Policy modification date below. Policy changes will apply only
        to information collected after the date of the change.
      </p>
      <h3>Feedback</h3>
      <p>
        If there are any questions regarding this privacy policy you may contact
        us using: <a href="mailto:kevin@quantif.ai">kevin@quantif.ai</a>
      </p>
      <Link to="/">Go back to the homepage</Link>
    </div>
  </Layout>
);

export default Privacy;
